import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../components/Login.vue'
import Home from '../views/Home.vue'

import User from '../views/User'
import Password from '../views/Password'
import Leave from '../views/Leave'
import Activity from '../views/Activity'
import Fundraising from '../views/Fundraising'
import Monitoring from '../views/Monitoring'
import Material from '../views/Material'

Vue.use(VueRouter)

const routes = [
  { path: '/',redirect: '/admin',name: 'Login', component: Login, hidden: true  },

  { path: '/admin', name: 'Login', component: Login, hidden: true},

  {
    path: '/admin/home',
    name: '后台管理',
    component: Home,
    children:[
      { path: '/admin/password', name: '修改密码',  component: Password},
      { path: '/admin/user', name: '用户管理',  component: User  },
      { path: '/admin/leave', name: '请假管理',  component: Leave  },
      { path: '/admin/material', name: '轮播图管理',  component: Material  },
      { path: '/admin/activity', name: '活动申请',  component: Activity  },
      { path: '/admin/fundraising', name: '班级筹款',  component: Fundraising },
      { path: '/admin/monitoring', name: '请假统计',  component: Monitoring  },
    ]
  },

]

const router = new VueRouter({
  routes
})

export default router
