<template>
  <div>
    <div style="margin-top: 20px;">
      <!-- 搜索 -->
      <el-date-picker
          size="mini"
          v-model="dates"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="margin-right: 10px;">
      </el-date-picker>
      <el-button size="small" type="primary" @click="search" icon="el-icon-search">搜索</el-button>
    </div>
    <div style="margin-top: 20px;">
      <!-- 搜索 -->
      <el-row :gutter="20">
        <el-col :span="24">
          <el-card>
            <div id="today" style="height: 100%;height:600px;"></div>
          </el-card>
        </el-col>
      </el-row>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Monitoring',
  data() {
    return {
      dates: null,
      data: [],
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    search(){
      console.log(this.dates)
      this.initData()
    },
    initData(){
      let startDate = ''
      let endDate = ''
      if(this.dates){
        startDate = this.dates[0]
        endDate = this.dates[1]
      }
      this.getRequest('/leave/statistics?startDate=' + startDate + '&endDate=' + endDate).then(resp => {
        console.log(resp)
        let data = resp.obj
        let name = []
        let value = []
        for (let i = 0; i < data.length; i++) {
          name.push(data[i].name)
          value.push(data[i].value)
        }
        this.initToDay(name, value)
      })
    },
    initToDay (name, value) {
      var myChart = this.$echarts.init(document.getElementById('today'));
      var option = option = {
        title : {
          text: '请假人数统计图',
          subtext: '请假',
          x:'center'
        },
        xAxis: {
          type: 'category',
          data: name
        },
        yAxis: {
          type: 'value'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        toolbox: {
          show : true,
          feature : {
            mark : {show: true},
            dataView : {show: true, readOnly: false},
            magicType : {
              show: true,
              type: ['pie', 'funnel'],
              option: {
                funnel: {
                  x: '25%',
                  width: '50%',
                  funnelAlign: 'left',
                  max: 1548
                }
              }
            },
            restore : {show: true},
            saveAsImage : {show: true}
          }
        },
        series: [
          {
            name: '人数',
            data: value,
            type: 'bar',
            barWidth: 40, //柱图宽度
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          }
        ]
      }
      myChart.setOption(option);
    }
  }
}
</script>

