<template>
  <div>
    <div style="margin-top: 20px;">
      <!-- 搜索 -->
      <el-input
        size="small"
        v-model="searchText"
        placeholder="请输入用户名，可回车搜索..."
        prefix-icon="el-icon-search"
        style="width: 400px;margin-right: 10px;"
        @keydown.enter.native="search"
        clearable
      ></el-input>
      <el-button size="small" type="primary" @click="search" icon="el-icon-search">搜索</el-button>
      <el-button size="small" type="success" @click="dialog_add = true" icon="el-icon-plus">新增</el-button>
      <el-button type="info" icon="el-icon-upload2" size="small" @click="handleImport">导入</el-button>
    </div>
    <div>
      <el-table :data="userData" style="width: 100%">
        <el-table-column label="序号" type="index" width="80"></el-table-column>
        <el-table-column label="用户名" :show-overflow-tooltip="true" prop="username" width="200"></el-table-column>
        <el-table-column label="昵称" :show-overflow-tooltip="true" prop="nickname" width="200"></el-table-column>
        <el-table-column label="手机号" prop="phone" :show-overflow-tooltip="true" width="200"></el-table-column>
        <el-table-column label="邮箱" prop="email" :show-overflow-tooltip="true" width="200"></el-table-column>
        <el-table-column label="类型" prop="type" width="100">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.type == 0" type="danger" size="medium">管理员</el-tag>
            <el-tag v-if="scope.row.type == 1" type="success" size="medium">学生</el-tag>
            <el-tag v-if="scope.row.type == 2" size="medium">老师</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createTime" width="180"></el-table-column>
        <el-table-column label="操作" width="260" fixed="right">
          <template slot-scope="scope">
            <el-button type="warning" size="mini" @click="handleRest(scope.$index, scope.row)">重置密码</el-button>
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页 -->
    <div style="margin-top: 20px;" v-if="showPage">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="pagesize"
        layout="total, prev, pager, next"
        :total="total"
      ></el-pagination>
    </div>

    <!-- 对话框 新建用户 -->
    <el-dialog title="新建用户" :visible.sync="dialog_add" width="30%">
      <el-form
        ref="form_add"
        :model="form_add"
        :rules="rules"
        label-width="80px"
        @keyup.enter.native="addUser()"
      >
        <el-form-item label="用户名" prop="username">
          <el-input v-model="form_add.username" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item label="用户昵称" prop="nickname">
          <el-input v-model="form_add.nickname" placeholder="请输入用户昵称"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="form_add.phone" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="form_add.email" placeholder="请输入邮箱"></el-input>
        </el-form-item>
        <el-form-item label="用户类型" prop="type">
          <el-radio v-model="form_add.type" :label="0">管理员</el-radio>
          <el-radio v-model="form_add.type" :label="1">学生</el-radio>
          <el-radio v-model="form_add.type" :label="2">老师</el-radio>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog_add = false">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 对话框 编辑用户 -->
    <el-dialog title="编辑用户" :visible.sync="dialog_edit" width="30%">
      <el-form
        ref="form_edit"
        :model="form_edit"
        :rules="rules"
        label-width="80px"
        @keyup.enter.native="updateUser()"
      >
        <el-form-item label="用户名" prop="username">
          <el-input v-model="form_edit.username" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item label="用户昵称" prop="nickname">
          <el-input v-model="form_edit.nickname" placeholder="请输入用户昵称"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="form_edit.phone" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="form_edit.email" placeholder="请输入邮箱"></el-input>
        </el-form-item>
        <el-form-item label="用户类型" prop="type">
          <el-radio v-model="form_edit.type" :label="0">管理员</el-radio>
          <el-radio v-model="form_edit.type" :label="1">学生</el-radio>
          <el-radio v-model="form_edit.type" :label="2">老师</el-radio>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog_edit = false">取 消</el-button>
        <el-button type="primary" @click="updateUser">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 用户导入对话框 -->
    <el-dialog :title="upload.title" :visible.sync="upload.open" width="400px" append-to-body>
      <el-upload
        ref="upload"
        :limit="1"
        accept=".xlsx, .xls"
        :action="upload.url"
        :disabled="upload.isUploading"
        :on-progress="handleFileUploadProgress"
        :on-success="handleFileSuccess"
        :auto-upload="false"
        drag
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将文件拖到此处，或
          <em>点击上传</em>
        </div>
        <div class="el-upload__tip text-center" slot="tip">
          <span>仅允许导入xls、xlsx格式文件。</span>
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitFileForm">确 定</el-button>
        <el-button @click="upload.open = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "User",
  data() {
    return {
      userData: [], //分类数据
      currentPage: 1, //当前页
      total: 0, //总记录数
      pagesize: 5, //页面大小
      searchText: "", //搜索框值
      dialog_add: false, //添加对话框
      dialog_edit: false, //编辑对话框
      allCommunity: null,
      form_add: {
        username: "",
        nickname: "",
        email: "",
        phone: "",
        type: 1
      },
      form_edit: {
        id: "",
        username: "",
        nickname: "",
        email: "",
        phone: "",
        type: null
      },
      showPage: true, //是否显示分页
      rules: {
        username: [
          { required: true, message: "用户名不能为空", trigger: "blur" }
        ]
      },
      roleList: null,
      // 用户导入参数
      upload: {
        // 是否显示弹出层（用户导入）
        open: false,
        // 弹出层标题（用户导入）
        title: "",
        // 是否禁用上传
        isUploading: false,
        // 是否更新已经存在的用户数据
        updateSupport: 0,
        // 上传的地址
        url: "/user/import"
      }
    };
  },
  mounted() {
    this.initUser();
  },
  methods: {
    //初始化数据
    initUser() {
      const _this = this;
      let params = {
        current: this.currentPage,
        size: this.pagesize,
        name: this.searchText
      };
      this.getRequest("/user/list", params).then(resp => {
        _this.userData = resp.obj.records;
        _this.total = resp.obj.total;
      });
    },
    //编辑
    handleEdit(index, row) {
      const _this = this;
      this.dialog_edit = true;
      this.getRequest("/user/getUserById?id=" + row.id).then(resp => {
        _this.form_edit = resp.obj;
      });
    },
    //更新
    updateUser() {
      const _this = this;
      this.putRequest("/user/updateUser", this.form_edit).then(resp => {
        if (resp) {
          this.initUser();
          this.$refs["form_edit"].resetFields();
          _this.dialog_edit = false;
        }
      });
    },
    //重置密码
    handleRest(index, row) {
      const _this = this;
      this.$confirm("此操作将重置该用户为：123456, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.deleteRequest("/user/restPasswordById?id=" + row.id).then(
            resp => {
              if (resp) {
                this.initUser();
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消重置"
          });
        });
    },
    //删除
    handleDelete(index, row) {
      const _this = this;
      this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.deleteRequest("/user/deleteUserById?id=" + row.id).then(resp => {
            if (resp) {
              this.initUser();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    //添加
    addUser() {
      const _this = this;
      this.postRequest("/user/saveUser", this.form_add).then(resp => {
        this.$refs["form_add"].resetFields();
        this.initUser();
        _this.dialog_add = false;
      });
    },
    //搜索
    search() {
      this.currentPage = 1;
      this.pagesize = 5;
      this.initUser();
    },
    handleSizeChange(val) {},
    handleCurrentChange(val) {
      this.currentPage = val;
      this.initUser();
    },

    // 文件上传中处理
    handleFileUploadProgress(event, file, fileList) {
      this.upload.isUploading = true;
    },
    // 文件上传成功处理
    handleFileSuccess(response, file, fileList) {
      this.upload.open = false;
      this.upload.isUploading = false;
      this.$refs.upload.clearFiles();
      this.$alert(
        "<div style='overflow: auto;overflow-x: hidden;max-height: 70vh;padding: 10px 20px 0;'>" +
          response.msg +
          "</div>",
        "导入结果",
        { dangerouslyUseHTMLString: true }
      );
      this.initUser();
    },
    // 提交上传文件
    submitFileForm() {
      this.$refs.upload.submit();
    },
    handleImport() {
      this.upload.title = "用户导入";
      this.upload.open = true;
    }
  }
};
</script>

<style scoped>
</style>
