<template>
  <div class="login-layout">
    <div class="container">
      <div class="login-panel-left">
        <img alt class="login-logo2" src="@/assets/123.png">
      </div>
      <div class="login-panel">
        <div class="head clearfix">
          <div class="logo">
            <img class="img-logo" src="@/assets/logo1.png">
          </div>
        </div>
        <el-form ref="loginForm" :model="loginForm" :rules="loginRules" auto-complete="on" label-position="left">
          <!-- <h3  class="login-head">管理员登录</h3> -->
          <div class="show-head" />
          <el-form-item class="form-group" prop="username">
            <el-input ref="username" v-model="loginForm.username" auto-complete="on" prefix-icon="el-icon-user" class="form-input" name="username" placeholder="用户名" tabindex="1" type="text" />
          </el-form-item>
          <el-form-item class="form-group" prop="password">
            <el-input
              ref="password"
              v-model="loginForm.password"
              show-password
              :type="passwordType"
              auto-complete="on"
              prefix-icon="el-icon-lock"
              class="form-input"
              name="password"
              placeholder="密码"
              tabindex="2"
            />
          </el-form-item>
          <el-button :loading="loading" class="submit-btn" type="primary" style="width:100%;margin-bottom:30px;" @click.native.prevent="handleBtn">登 录</el-button>
          <div style="margin-bottom: 10px; font-size: 12px; color: rgb(153, 153, 153);">适用浏览器：Chrome QQ浏览器 360极速 FireFox 搜狗.</div>
        </el-form>
      </div>
    </div>
  
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined,
      otherQuery: {},
      show: false,
    }
  },
  watch: {

  },
  methods: {
    handleBtn(){
      this.$refs.loginForm.validate(validate => {
        // Element自带的校验
        if (validate) {
          //显示加载样式
          this.loading = true;
          //这是在api.js封装的请求
          this.postKeyValueRequest("/doLogin", this.loginForm).then(resp => {
            //隐藏加载样式
            this.loading = false;
            if (resp) {
              //resp：从服务端拿到的数据  用户的数据要保存到哪里？ 保存在sessionStorage  关闭浏览器就没了
              window.sessionStorage.setItem("user", JSON.stringify(resp.obj));
              //页面跳转  replace：替换  用push的话，可以使用后退按钮回到登录页，用replace不可以回到登录页
              this.$router.replace("/admin/home");
            }
          });
        } else {
          this.$message.error("请输入所有字段");
          return false;
        }
      });
    }
  }
}
</script>

<style lang="scss">
.login-layout {
  height: 100vh;
  background: #e7e5ef;

  .container {
    position: absolute;
    width: 820px;
    height: 420px;
    left: 50%;
    top: 42%;
    margin: -225px 0 0 -410px;
    background: #ffffff;
    box-shadow: 0px 0px 26px #e3e3e3;
    border-radius: 8px;

    .login-panel-left {
        float: left;
        width: 400px;
        height: 420px;
        background: #f4f9fd;
        border-radius: 6px 0px 0px 6px;
    }

    .login-logo2 {
        max-width: 100%;
        max-height: 100%;
        padding: 100px;
    }
  }

  #captcha {
    height: 50px;

    &.hideHeight {
      height: 0;
    }
  }

  .head {
    .logo {
      min-height: 67px;
      text-align: center;

      img {
        margin-top: 16%;
        width: 250px;
        vertical-align: top;
      }
    }

    .name-panel {
      float: left;
      color: #fff;
      padding-top: 18px;
    }

    .name {
      font-size: 26px;
      font-weight: 700;
      line-height: 1;
    }

    .tip {
      padding-top: 6px;
      margin: 0;
    }
  }

  .login-panel {
    box-sizing: initial;
    float: right;
    width: 350px;
    height: 420px;
    background: #fff;
    border-radius: 0 6px 6px 0;
    padding: 0 35px;
    box-shadow: 0 0 0px rgba(0, 0, 0, 0.55);
  }

  .login-head {
    padding-top: 60px;
    padding-bottom: 20px;
    font-weight: bold;
    font-size: 24px;
    line-height: 34px;
    color: #333333;
    margin: 0;
  }

  .show-head {
    padding-top: 40px;
    // padding-bottom: 20px;
    // height: 34px;
  }

  .form-group {
    height: 50px;
    border-bottom: 1px solid #e1e1e1;
    border-radius: 3px;
    margin-bottom: 20px;
    position: relative;
  }

  .form-group .label {
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    line-height: 50px;
    height: 50px;
    font-size: 17px;
    text-align: center;
  }

  .form-group .form-input {
    box-sizing: border-box;
    height: 50px;

    input {
      border: none;
      height: 100%;
      width: 100%;
    //   padding: 0;
      box-sizing: border-box;
    }
  }

  .check-group .form-input {
    padding-left: 12px;
  }

  .check-group .check-img {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 105px;
    height: 30px;
  }

  .form-group .icon {
    width: 20px;
  }

  .check-slide-group {
    overflow: visible;
    transition: border 0.5s;

    .tip-text {
      position: absolute;
      top: 1px;
      left: 1px;
      right: 1px;
      bottom: 1px;
      z-index: 9;
      line-height: 50px;
      text-align: center;
    }

    .el-form-item__error {
      position: absolute;
      left: 0;
      right: 0;
      top: 52px;
    }

    &.error {
      border-color: #f56c6c;
    }

    &.success {
      border-color: #67c23a;
    }

    &:hover {
      border-color: #333;
      cursor: pointer;
    }
  }

  .slide-panel-head {
    line-height: 42px;
  }

  .slide-panel {
    background-color: #fff;
    position: absolute;
    left: -15px;
    bottom: 10px;
    z-index: 99;
    padding: 0 10px 10px;
    border: 1px solid #eee;
    box-shadow: 0 0 5px #aaa;

    .arrow {
      position: absolute;
      bottom: -9px;
      left: 50%;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      border-width: 8px;
      margin-left: -3px;
      border-bottom-width: 0;
      border-top-color: #dbdcde;

      &:after {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        content: ' ';
        border-width: 8px;
        border-color: transparent;
        border-style: solid;
        top: -9px;
        margin-left: -8px;
        border-bottom-width: 0;
        border-top-color: #fff;
      }
    }

    .refresh-btn {
      color: #409eff;
    }
  }

  .submit-btn {
    margin-top: 20px;
    width: 300px;
    height: 44px;
    background: #2388ec;
    border-radius: 22px;

    &:active {
      opacity: 0.6;
    }
  }
}

.flex_code {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .flex_code_input {
    margin-right: 10px;
    min-width: 50px !important;
  }
}

.img_code {
  width: 80px;
  cursor: pointer;
}

.svg-container {
    // padding: 6px 5px 6px 15px;
    // color: $dark_gray;
    // vertical-align: middle;
    // width: px;
    // display: inline-block;
}
</style>
