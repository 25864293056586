<template>
  <div>
    <div style="margin-top: 20px;">
      <!-- 搜索 -->
      <el-input
        size="small"
        v-model="searchText"
        placeholder="请输入活动内容，可回车搜索..."
        prefix-icon="el-icon-search"
        style="width: 400px;margin-right: 10px;"
        @keydown.enter.native="search"
        clearable
      ></el-input>
      <el-button size="small" type="primary" @click="search" icon="el-icon-search">搜索</el-button>
    </div>
    <div>
      <el-table :data="data" style="width: 100%">
        <el-table-column label="序号" type="index" width="80"></el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="班级" prop="theClass">
          <template slot-scope="scope">{{scope.row.theClass?scope.row.theClass.name:''}}</template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="活动内容" prop="content"></el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="通知用户" prop="user">
          <template slot-scope="scope">{{scope.row.user?scope.row.user.nickname:''}}</template>
        </el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
        <el-table-column label="操作" width="200" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页 -->
    <div style="margin-top: 20px;" v-if="showPage">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="pagesize"
        layout="total, prev, pager, next"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheClass",
  data() {
    return {
      data: [], //分类数据
      currentPage: 1, //当前页
      total: 0, //总记录数
      pagesize: 5, //页面大小
      searchText: "", //搜索框值
      showPage: true //是否显示分页
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    //初始化分类数据
    initData() {
      const _this = this;
      let params = {
        current: this.currentPage,
        size: this.pagesize,
        name: this.searchText
      };
      this.getRequest("/activity/list", params).then(resp => {
        _this.data = resp.obj.records;
        _this.total = resp.obj.total;
      });
    },
    //删除
    handleDelete(index, row) {
      const _this = this;
      this.$confirm("此操作将永久删除该条记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.deleteRequest("/activity/delete?id=" + row.id).then(resp => {
            if (resp) {
              this.initData();
            }
          });
        })
        .catch(() => {});
    },
    //搜索分类
    search() {
      this.currentPage = 1;
      this.pagesize = 5;
      this.initData();
    },
    handleSizeChange(val) {},
    handleCurrentChange(val) {
      this.currentPage = val;
      this.initData();
    }
  }
};
</script>
