<template>
    <div>
        <div style="margin-top: 20px;">
            <!-- 搜索 -->
            <el-input size="small" v-model="searchText" placeholder="请输入名称，可回车搜索..." prefix-icon="el-icon-search"
                style="width: 400px;margin-right: 10px;" @keydown.enter.native="search" clearable></el-input>
            <el-button size="small" type="primary" @click="search" icon="el-icon-search">搜索</el-button>
            <el-button size="small" type="success" @click="handleAdd()" icon="el-icon-plus">新增</el-button>
        </div>
        <div>
            <el-table :data="userData" style="width: 100%">
                <el-table-column label="序号" type="index" width="80"></el-table-column>
                <el-table-column label="名称" :show-overflow-tooltip="true" prop="name" width="200"></el-table-column>
                <el-table-column label="图片" prop="url" width="200">
                    <template slot-scope="scope">
                        <el-image style="width: 100px; height: 100px" :src="scope.row.url"
                            @click="imgBlowClick(scope.row.url)"></el-image>
                        <!-- 遮罩层出现，里面有一张75%大小的img -->
                        <el-dialog :visible.sync="disabledImgBlow" append-to-body title="查看图片">
                            <!-- 居中显示图片 -->
                            <div style="text-align: center;">
                                <img :src="imgBlowData" style="width: 75%;" />
                            </div>
                        </el-dialog>
                    </template>
                </el-table-column>
                <el-table-column label="状态" prop="status" :show-overflow-tooltip="true" width="200">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.status == 'Y'" type="success">启用</el-tag>
                        <el-tag v-else type="danger">禁用</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" prop="createTime" width="180"></el-table-column>
                <el-table-column label="操作" fixed="right">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                        <el-button size="mini" type="danger"
                            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!-- 分页 -->
        <div style="margin-top: 20px;" v-if="showPage">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page.sync="currentPage" :page-size="pagesize" layout="total, prev, pager, next"
                :total="total"></el-pagination>
        </div>

        <!-- 对话框 新建用户 -->
        <el-dialog :title="dialogTitle" :visible.sync="dialog_add" width="30%">
            <el-form ref="form_add" :model="form_add" :rules="rules" label-width="80px"
                @keyup.enter.native="addMaterial()">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="form_add.name" placeholder="请输入名称"></el-input>
                </el-form-item>
                <el-form-item label="图片" prop="url">
                    <el-upload class="avatar-uploader" :action="upload.url" ref="upload"
                        :show-file-list="false" :on-success="handleFileSuccess" :before-upload="beforeAvatarUpload">
                        <img v-if="form_add.url" :src="form_add.url" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="状态" prop="status">
                    <el-tooltip :content="form_add.status == 'Y' ? '启用' : '禁用'" placement="top">
                        <el-switch v-model="form_add.status" active-color="#13ce66" inactive-color="#ff4949"
                            active-value="Y" inactive-value="N">
                        </el-switch>
                    </el-tooltip>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialog_add = false">取 消</el-button>
                <el-button type="primary" @click="form_add.id ? updateMaterial() : addMaterial()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: "User",
    data() {
        return {
            userData: [], //分类数据
            currentPage: 1, //当前页
            total: 0, //总记录数
            pagesize: 5, //页面大小
            searchText: "", //搜索框值
            dialog_add: false, //对话框
            allCommunity: null,
            form_add: {
                id: "",
                name: "",
                url: "",
                status: ""
            },
            showPage: true, //是否显示分页
            rules: {
                name: [
                    { required: true, message: "请输入名称", trigger: "blur" }
                ],
                url: [
                    { required: true, message: "请上传图片", trigger: "blur" }
                ],
                status: [
                    { required: true, message: "请选择状态", trigger: "blur" }
                ]
            },
            roleList: null,
            // 文件上传参数
            upload: {
                // 上传的地址
                url: "/uploadFile"
            },
            disabledImgBlow: false,
            imgBlowData: '',
            dialogTitle: ''
        };
    },
    mounted() {
        this.initData();
    },
    methods: {
        handleAdd() {
            this.dialog_add = true;
            this.dialogTitle = '新增';
        },
        beforeAvatarUpload(file) {
        // 只能是jpeg/png/gif
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error('上传图片只能是 JPG、PNG、GIF 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传图片大小不能超过 2MB!');
        }
        return isJPG && isLt2M;
      },
        // 图片放大
        imgBlowClick(url) {
            this.imgBlowData = url;
            this.disabledImgBlow = true;
        },
        //初始化数据
        initData() {
            const _this = this;
            let params = {
                current: this.currentPage,
                size: this.pagesize,
                name: this.searchText
            };
            this.getRequest("/material/list", params).then(resp => {
                _this.userData = resp.obj.records;
                _this.total = resp.obj.total;
            });
        },
        //编辑
        handleEdit(index, row) {
            const _this = this;
            this.dialog_add = true;
            this.dialogTitle = '修改';
            this.getRequest("/material/getById?id=" + row.id).then(resp => {
                _this.form_add = resp.obj;
            });
        },
        //更新
        updateMaterial() {
            const _this = this;
            this.putRequest("/material/update", this.form_add).then(resp => {
                if (resp) {
                    this.initData();
                    this.resetForm();
                    _this.dialog_add = false;
                }
            });
        },

        //删除
        handleDelete(index, row) {
            const _this = this;
            this.$confirm("此操作将永久删除该图片, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    this.deleteRequest("/material/delete?id=" + row.id).then(resp => {
                        if (resp) {
                            this.initData();
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除"
                    });
                });
        },
        //添加
        addMaterial() {
            const _this = this;
            this.postRequest("/material/add", this.form_add).then(resp => {
                this.resetForm();
                this.initData();
                _this.dialog_add = false;
            });
        },
        //搜索
        search() {
            this.currentPage = 1;
            this.pagesize = 5;
            this.initData();
        },
        handleSizeChange(val) { },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.initData();
        },
        // 文件上传成功处理
        handleFileSuccess(response, file, fileList) {
            this.$refs.upload.clearFiles();  
            // this.imageUrl = URL.createObjectURL(file.raw);
            this.form_add.url = response.obj.fileUrl;
            // 提示
            this.$message({
                message: "上传成功",
                type: "success"
            });
        },
        // 清空表单
        resetForm() {
            this.form_add = {
                id: "",
                name: "",
                url: "",
                status: "Y"
            };
        }
    }
};
</script>

<style scoped>

.avatar-uploader {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 178px;
    height: 178px;
}

.avatar-uploader .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar-uploader .avatar{
    width: 178px;
    height: 178px;
    display: block;
}


</style>