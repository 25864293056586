<template>
  <div>
    <div style="margin-top: 20px;">
      <!-- 搜索 -->
      <el-input
        size="small"
        v-model="searchText"
        placeholder="请输入请假类型，可回车搜索..."
        prefix-icon="el-icon-search"
        style="width: 400px;margin-right: 10px;"
        @keydown.enter.native="search"
        clearable
      ></el-input>
      <el-button size="small" type="primary" @click="search" icon="el-icon-search">搜索</el-button>
    </div>
    <div>
      <el-table :data="data" style="width: 100%">
        <el-table-column label="序号" type="index" width="80"></el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="班级" prop="theClass">
          <template slot-scope="scope">{{scope.row.theClass?scope.row.theClass.name:''}}</template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="请假人" prop="user">
          <template slot-scope="scope">{{scope.row.user?scope.row.user.nickname:''}}</template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="请假类型" prop="type"></el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="状态" prop="status">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status == 1" type="primary" size="medium">待审批</el-tag>
            <el-tag v-if="scope.row.status == 2" type="success" size="medium">审批通过</el-tag>
            <el-tag v-if="scope.row.status == 3" type="danger" size="medium">审批不通过</el-tag>
            <el-tag v-if="scope.row.status == 4" type="warning" size="medium">待管理员审批</el-tag>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="请假内容" prop="content"></el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
        <el-table-column label="操作" width="200" fixed="right">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.status == 4"
              size="mini"
              @click="handleAudit(scope.$index, scope.row)"
            >审批</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页 -->
    <div style="margin-top: 20px;" v-if="showPage">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="pagesize"
        layout="total, prev, pager, next"
        :total="total"
      ></el-pagination>
    </div>

    <!-- 对话框 编辑 -->
    <el-dialog title="审批" :visible.sync="editDialog" width="30%">
      <el-form
        ref="editFrom"
        :model="editFrom"
        :rules="rules"
        @keyup.enter.native="update()"
        label-width="100px"
      >
        <el-form-item label="审批结果" prop="status">
          <el-radio-group v-model="editFrom.status">
            <el-radio :label="2">通过</el-radio>
            <el-radio :label="3">不通过</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialog = false">取 消</el-button>
        <el-button type="primary" @click="update">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "TheClass",
  data() {
    return {
      data: [], //分类数据
      currentPage: 1, //当前页
      total: 0, //总记录数
      pagesize: 5, //页面大小
      searchText: "", //搜索框值
      showPage: true, //是否显示分页
      editDialog: false, //编辑的对话框
      editFrom: {
        id: null,
        status: ""
      },
      rules: {
        status: [{ required: true, message: "请选择审批结果", trigger: "blur" }]
      }
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    //初始化分类数据
    initData() {
      const _this = this;
      let params = {
        current: this.currentPage,
        size: this.pagesize,
        name: this.searchText
      };
      this.getRequest("/leave/list", params).then(resp => {
        _this.data = resp.obj.records;
        _this.total = resp.obj.total;
      });
    },
    //编辑
    handleAudit(index, row) {
      this.editFrom.id = row.id;
      this.editDialog = true;
    },
    //更新分类
    update() {
      const _this = this;
      this.$refs["editFrom"].validate(valid => {
        if (valid) {
          this.putRequest("/leave/update", this.editFrom).then(resp => {
            if (resp) {
              this.initData();
              this.$refs["editFrom"].resetFields();
              _this.editDialog = false;
            }
          });
        }
      });
    },
    //删除
    handleDelete(index, row) {
      const _this = this;
      this.$confirm("此操作将永久删除该条记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.deleteRequest("/leave/delete?id=" + row.id).then(resp => {
            if (resp) {
              this.initData();
            }
          });
        })
        .catch(() => {});
    },
    //搜索分类
    search() {
      this.currentPage = 1;
      this.pagesize = 5;
      this.initData();
    },
    handleSizeChange(val) {},
    handleCurrentChange(val) {
      this.currentPage = val;
      this.initData();
    }
  }
};
</script>
